// see date formats here https://day.js.org/docs/en/display/format
// [h] - escaping characters
import {PLACEHOLDER, ROUND_OPTION, NON_BREAK_SPACE, DEFAULT_PRICE_DELIMITER} from './const';

export const LANG_SETTING = {
    ru: {
        DateText: `Цена на ${PLACEHOLDER.DateFormat}`,
        DateFormat: `DD${DEFAULT_PRICE_DELIMITER}MM${DEFAULT_PRICE_DELIMITER}YYYY`,
        MinLosText: `При бронировании от ${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosEnd} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `по тарифу «${PLACEHOLDER.Offer}»`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    en: {
        DateText: `Rate available ${PLACEHOLDER.DateFormat}`,
        DateFormat: `MM${NON_BREAK_SPACE}DD,${NON_BREAK_SPACE}YYYY`,
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
};
